const navBarEntries = [
    {
        id: 1,
        text: 'Vendors',
        target: '/partners/vendors'
    }
]

const routes = [
    {
        path: '/partners/vendors',
        name: 'Vendors',
        component: () => import('./views/Vendors.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/vendor/:vendor_nid',
        name: 'Vendor',
        component: () => import('./views/Vendor.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/vendor/:vendor_nid/sales-regions',
        name: 'Sales Regions',
        component: () => import('./views/SalesRegions.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/sales-region/:sales_region_nid',
        name: 'Sales Region',
        component: () => import('./views/SalesRegion.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/vendor/:vendor_nid/sales-offices',
        name: 'Sales Offices',
        component: () => import('./views/SalesOffices.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/sales-office/:sales_office_nid',
        name: 'Sales Office',
        component: () => import('./views/SalesOffice.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/vendor/:vendor_nid/sales-agents',
        name: 'Sales Agents',
        component: () => import('./views/SalesAgents.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/partners/sales-agent/:sales_agent_nid',
        name: 'Sales Agent',
        component: () => import('./views/SalesAgent.vue'),
        meta: {
            nav: navBarEntries
        }
    },
]

export default routes